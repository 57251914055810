<script>
import Header from "./components/modules/Header";

export default {
  name: 'App',
  components: {
    Header,
  }
}
</script>

<template>
  <div id="app">
    <div id="nav">
      <Header />
    </div>
    <v-main>
        <router-view />
    </v-main>
  </div>
</template>

