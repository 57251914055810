<script>
import router from '../../router'

export default {
  props: ['blog'],
  methods: {
    show_more_like_this(blog_id) {
        router.replace({ path: `/morelikethis/${blog_id}`})
    }
  }
}
</script>

<template>
  <div class="card m-2 p-1 shadow">
    <div class="card-body">
      <h2>
        <a v-bind:href="blog.url" class="text-dark text-decoration-none font-weight-bold" target="_blank">{{ blog.title }}</a>
        <button class="btn" style="float: right;" v-on:click="show_more_like_this(blog.id)" >
          <font-awesome-icon icon="hat-wizard" size="lg" /> More like this
        </button>
      </h2>
      <p class="text-muted">{{ blog.subtitle }} ...</p>
      <span v-for="tag in blog.tags" :key="tag.id">
        <span class="tag badge bg-light text-dark">{{ tag.name }}</span>
      </span>
      <div class="row mt-1">
        <div class="col-sm">
          <p class="published_at">{{ blog.published_at.substring(0,10) }}</p>
        </div>
        <div class="col-sm">
          <p class="publisher">
            <a v-bind:href="blog.publisher.url" class="text-dark text-decoration-none">{{ blog.publisher.name }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.card .h2 {
  font-size: 1em;
}
.card .p {
  font-size: 1em;
}
.card .published_at {
  text-align: left;
  font-size: 1em;
  /* font-weight: bold; */
}
.card  .publisher {
  text-align: right;
  font-size: 1em;
  font-weight: bold;
}
</style>
