
<script>
import axios from 'axios';
import BlogPost from "../components/modules/BlogPost";

var api = '';
if (typeof process.env.VUE_APP_PLATYPUS_BACKEND_URI === 'undefined') {
  api = "http://localhost:8000";
} else {
  api = process.env.VUE_APP_PLATYPUS_BACKEND_URI;
}

export default {
  name: 'MoreLikeThis',
  components: {
    BlogPost
  },
  data(){
    return {
      blogs_more_like_this: [],
      target_blog: {},
    }
  },
  computed: {
  },
  created: function(){
    this.get_more_like_this(this.$route.params.blog_id);
    this.get_target_blog(this.$route.params.blog_id);
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    get_more_like_this(blog_id) {
      axios.get(api + '/api/recommendations/' + blog_id).then(({ data }) => {
        this.blogs_more_like_this=[];
        this.blogs_more_like_this.push(...data);
      });
    },
    get_target_blog(blog_id) {
      axios.get(api + '/api/blogs/' + blog_id).then(({ data }) => {
        this.target_blog = data;
      });
    },
    fetchData () {
      const fetchedId = this.$route.params.blog_id
      // TODO: manage loading status
      // TODO: check if the blog_id is the same as before
      this.get_more_like_this(fetchedId);
      this.get_target_blog(fetchedId);
    },
  }
}
</script>

<template>
  <div id="more_like_this">
    <div class="container">
      <div class="more-like-this-header m-2 p-2">
        <h2 class="text-center">
          More Like
          <a v-bind:href="target_blog.url" class="text-dark text-decoration-none font-weight-bold" target="_blank">"{{ target_blog.title }}"</a>
          by
          <a v-bind:href="target_blog.publisher.url" class="text-dark text-decoration-none">{{ target_blog.publisher.name }}</a>
        </h2>
      </div>
      <ul class="list-group">
        <li  class="list-group-item">
          <BlogPost v-for="blog in blogs_more_like_this" :key="blog.id" :blog="blog" />
        </li>
      </ul>
    </div>
  </div>
</template>
