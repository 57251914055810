<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import BlogPost from "../components/modules/BlogPost";

var api = '';
if (typeof process.env.VUE_APP_PLATYPUS_BACKEND_URI === 'undefined') {
  api = "http://localhost:8000";
} else {
  api = process.env.VUE_APP_PLATYPUS_BACKEND_URI;
}

export default {
  name: 'Home',
  components: {
    InfiniteLoading,
    BlogPost
  },
  data(){
    return {
      search_input: {text: "", filter: null},
      offset: 0,
      result_count: 0,
      blogs: [],
      popular_tags: [],
      selected_tag_id: null,
      search_query: "",
      infiniteId: +new Date(),
      api_path: "/api/blogs/?",
    }
  },
  created : function(){
    this.get_pupular_tags();
  },
  methods: {
    infiniteHandler($state) {
      axios.get(api + this.api_path + "offset=" + this.offset).then(({ data }) => {
        if (data.results.length) {
          this.result_count = data.count;
          this.offset += 100;
          this.blogs.push(...data.results);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    search_text() {
      this.search_query = this.search_input.text;
      this.offset = 0;
      this.api_path = "/api/blogs/?search=" + this.search_query + "&";
      this.blogs = [];
      this.infiniteId += 1;
      this.selected_tag_id = null;
    },
    get_pupular_tags() {
      axios.get(api + "/api/tags/popular_tags/").then(({ data }) => {
        this.popular_tags = data;
      });
    },
    get_tag_blogs(tag_id) {
      this.offset = 0;
      this.api_path = "/api/tags/" + tag_id + "/blogs/?";
      this.selected_tag_id = tag_id;
      this.blogs = [];
      this.infiniteId += 1;
    }
  }
}
</script>

<template>
  <div id="app">
    <div class="container">
      <div class="container">
        <div class="row height d-flex justify-content-center align-items-center">
          <div class="col-md-6">
            <div class="search">
              <font-awesome-icon icon="search"/>
              <input v-model="search_input.text" @keypress.enter="search_text" type="text" class="form-control" placeholder="Search anything...">
            </div>
          </div>
        </div>
      </div>
      <div class="tag-group">
        <span v-for="tag in popular_tags" :key="tag.id">
          <button class="tag btn rounded-pill" :class="[ tag.id === selected_tag_id ? 'btn-secondary' : 'btn-light' ]" v-on:click="get_tag_blogs(tag.id)" >{{ tag.name }}</button>
        </span> 
      </div>
      <ul class="list-group">
        <li  class="list-group-item">
          <BlogPost v-for="blog in blogs" :key="blog.id" :blog="blog" />
        </li>
      </ul>
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading>
    </div>
  </div>
</template>


<style>
.list-group-item {
  border: 0 !important;
}

.tag {
  margin: 0.2em;
  font-size: .9em;
}

.search {
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    margin: .5em;
}

.search input {
    height: 60px;
    text-indent: 25px;
    border: 2px solid #d6d4d4
}

.search input:focus {
    box-shadow: none;
    border: 2px solid blue
}

.search .fa-search {
    position: absolute;
    top: 20px;
    left: 16px
}
.search .fa-search {
    position: absolute;
    top: 20px;
    left: 16px
}

.search button {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    background: blue
}
</style>
